<template lang="pug">
  .calendar-view
    v-container(fluid)
      v-row(justify="center")
        v-toolbar(flat tile color="transparent" ).text-center
          v-btn(@click="$refs.calendar.prev()" text).text-decoration-none
            v-icon.mr-3 fa-arrow-left
            |  {{$t('events.prevMonth')}}
          v-spacer
          v-toolbar-title.mx-3
            span(v-if="$refs.calendar && $refs.calendar.title&& $refs.calendar.title!==''") {{$refs.calendar.title}}
            span(v-else) {{currentDate}}
            span  ({{currentEvents.length}} {{$t('events.heading')}})
          v-spacer

          v-btn(@click="$refs.calendar.next()" text).text-decoration-none {{$t('events.nextMonth')}}
            v-icon.ml-3 fa-arrow-right
    v-container(fluid)
      v-row(justify="center")
        v-col.text-center
          v-sheet(height="800").pa-4
            v-calendar(
              @change="getEvents"
              ref="calendar"
              :events="currentEvents"
              v-model="calendarValue"
              :weekdays="calendarWeekday"
              :type="calendarType"
              event-color="#8a2387"
              @click:event="clickEvent"
            )

</template>

<script>
export default {
  name: 'app-events-calendar',
  props: { events: Array },
  data () {
    return {
      currentEvents: [],
      calendarWeekday: [1, 2, 3, 4, 5, 6, 0],
      calendarType: 'month',
      calendarValue: '',
      calendarTitle: null
    }
  },
  methods: {
    clickEvent (event) {
      this.$router.push({ name: 'EventsDetail', params: { eventId: event.event.id } })
    },
    getEvents ({ start, end }) {
      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      this.currentEvents = this.events
        .filter(e => min.getTime() <= e.date.getTime() && e.date.getTime() <= max.getTime())
        .map(e => {
          return {
            id: e.id,
            name: e.name,
            start: new Date(e.date.getTime()),
            end: new Date(e.date.getTime() + (1000 * 60 * 60 * 24 - 1)),
            timed: false
          }
        })
    }
  },
  computed: {
    currentDate () {
      return this.$d(new Date(), 'monthYear')
    },
    currentDates () {
      // console.log(this.events.filter(e => e.date))
      return this.events
    }
  },
  mounted () {
    this.$refs.calendar.checkChange()
  }
}
</script>

<style scoped>

</style>
